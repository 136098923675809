import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerBaseAPI } from "../../../api";
import { IProfileReducerState } from "./Interfaces";



export const fetchUserProfileThunk = createAsyncThunk<IProfileReducerState, string>(
    'profile/fetchUserProfileThunk',
    async (profileId, { rejectWithValue }) => {
        const response = await ServerBaseAPI['get'](`/profile/${profileId}`)

        if (response.status !== 200) {
            return rejectWithValue({
                message: "Failed to Get Employee Data"
            });
        }
        return response.data.data as IProfileReducerState
    }
)


export const updateUserProfileThunk = createAsyncThunk<IProfileReducerState, IProfileReducerState>(
    'profile/updateUserProfileThunk',
    async (profileInfo, { rejectWithValue }) => {
        const response = await ServerBaseAPI['put']('/employee', profileInfo)

        if (response.status !== 201) {
            return rejectWithValue({
                message: "Failed to Add Image Layer."
            });
        }
        return response.data.data as IProfileReducerState
    }
)