import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerBaseAPI } from "../../../api";
import { IArticle } from "../../../interfaces";


export const fetchArticlesThunk = createAsyncThunk<IArticle[], void>(
    'article/fetchArticlesThunk',
    async (_: void, { rejectWithValue }) => {
        const response = await ServerBaseAPI['get'](`/article`)

        if (response.status !== 200) {
            return rejectWithValue({
                message: "Failed to Get Employee Data"
            });
        }
        return response.data.data as IArticle[]
    }
)

export const fetchArticleByIdThunk = createAsyncThunk<IArticle[], string>(
    'article/fetchArticleByIdThunk',
    async (article_id: string, { rejectWithValue }) => {
        const response = await ServerBaseAPI['get'](`/article/${article_id}`)

        if (response.status !== 200) {
            return rejectWithValue({
                message: "Failed to Get Employee Data"
            });
        }
        return response.data.data as IArticle[]
    }
)

export const fetchUserArticlesThunk = createAsyncThunk<IArticle[], string>(
    'article/fetchUserArticlesThunk',
    async (userId: string, { rejectWithValue }) => {
        const response = await ServerBaseAPI['get'](`/article/user/${userId}`)

        if (response.status !== 200) {
            return rejectWithValue({
                message: "Failed to Get user Articles"
            });
        }
        return response.data.data as IArticle[]
    }
)

export const createNewArticleThunk = createAsyncThunk<IArticle, IArticle>(
    'article/createNewArticleThunk',
    async (blogState, { rejectWithValue }) => {
        const response = await ServerBaseAPI['post']('/article', blogState)

        if (response.status !== 201) {
            return rejectWithValue({
                message: "Failed to Add New Article."
            });
        }
        return response.data.data as IArticle
    }
)