import { ServerBaseAPI } from "../api";


const PROFILE_BASE_PATH = "/profile";



export const CheckUsernameAvailabilty = (username: string) => ServerBaseAPI['post'](`${PROFILE_BASE_PATH}/uname`, { username: username })
export const UpdateProfileUsername = (profileId: string, username: string) => ServerBaseAPI.post(`${PROFILE_BASE_PATH}/${profileId}`, { username })

export const FetchProfileByUsername = (username: string) => ServerBaseAPI['get'](`${PROFILE_BASE_PATH}/uname/${username}`)