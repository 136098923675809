import { Button, styled, Typography } from '@mui/material';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import React, { useEffect, useState } from 'react'
import { Editor, EditorState as IEditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ColorSchem } from '../../constants';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ToolbarConfig } from './ToolbarConfig';
import { UploadFileToServer } from '../../helpers/SaveUserPost';

const EditorPage = styled('div')(({ theme }) => ({
    background: '#efefef',
}));

const EditorTool = styled('div')(({ theme }) => ({
    background: ColorSchem.textColor
}));

interface ITextEditor {
    initalState: string | null
    previewMode: boolean
    onChange: (val: string) => void
}
export const TextEditor = ({ initalState, previewMode, onChange }: ITextEditor) => {
    const [editorState, setEditorState] = useState<IEditorState>(EditorState.createEmpty())

    useEffect(() => {
        const propSate = initalState || '<p id="para">Hey, Write your story here 🖊</p>'
        const contentState = ContentState.createFromBlockArray(htmlToDraft(propSate).contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
    }, [])

    useEffect(() => {
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])


    const createMarkup = () => {
        return { __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) };
    }

    function uploadImageCallBack(file: File) {
        return UploadFileToServer(file)
    }


    return (
        <EditorPage >
            <EditorTool>
                {previewMode ?
                    <div style={{ padding: 20 }} dangerouslySetInnerHTML={createMarkup()}></div>
                    :
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        toolbarClassName="demo-toolbar"
                        toolbar={{
                            image: {
                                uploadCallback: uploadImageCallBack,
                                inputAccept: "image/png",
                                alt: { present: true, mandatory: false }
                            }
                        }}
                    />
                }
            </EditorTool>
        </EditorPage >
    )
}
