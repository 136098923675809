import { styled } from "@mui/material";



export const Divider = styled('div')(({ theme }) => ({
    width: '51ch',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'uppercase',
    border: 'none',
    fontSize: 12,
    fontWeight: 500,
    margin: 0,
    padding: '24px 0 0',
    ["::before"]: {
        content: '""',
        borderBottom: '1px solid #c2c8d0',
        flex: '1 0 auto',
        height: '0.8em',
        margin: 0,
    },
    ["::after"]: {
        content: '""',
        borderBottom: '1px solid #c2c8d0',
        flex: '1 0 auto',
        height: '0.8em',
        margin: 0,
    },
}))