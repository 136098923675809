

export const ColorSchem = {
    mainColor: '#29335C',
    mainColorLight: "#5767aa",
    secondaryColor: '#DB2B39',
    textColor: '#eee',
    bgDark: '#000',
    bgLight: '#4b4b4b',
    white: '#fff',
    bodyLight: '#f7f7f7'
}