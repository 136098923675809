import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { LoginPage } from '../Auth'
import { EditorPage } from './EditorPage'

export const WriteNewArticle = () => {
  const nevigate = useNavigate()
  const { isLoggedIn } = useAuth()
  // if (!isLoggedIn) {
  //   nevigate('/login')
  //   return <LoginPage/>
  // }

  return (
    <EditorPage />
  )
}
