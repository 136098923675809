import { Button, Container, Grid, List, ListItemButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { ProfilePicture } from '../../components/Navbar/ProfilePicture';
import { useAppSelector } from '../../redux';
import { selectProfileState } from '../../redux/reducers/profile';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { IProfile } from '../../interfaces/IProfile';
import { CheckUsernameAvailabilty } from '../../services/ProfileService';
import { SocialLinksSection } from './SocialLinksSection';

export const UserSettings: React.FC = () => {
  let navigate = useNavigate()
  const { profile: profileValues } = useAppSelector(selectProfileState)
  const [profileType, setProfileType] = React.useState<"info" | "logs" | "social" | "username">('info')
  const [profileState, setProfileState] = React.useState<IProfile | null>(profileValues)
  const [unameError, setUnameError] = React.useState({ error: false, message: '' })

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: (values) => {
      let error: any = {}
      if (values.oldPassword === '') {
        error.oldPassword = "Name shouldn't be empty."
      } else if (values.newPassword === '') {
        error.newPassword = "New Password Field Shouldn't be empty."
      } else if (values.newPassword.length < 8) {
        error.newPassword = "Password minimum lenght should be 8."
      } else if (values.confirmNewPassword !== values.newPassword) {
        error.confirmNewPassword = "New Password & Confir New Password doesn't matched"
      }
      return error
    },
    onSubmit: (values) => {
      alert(`values====>" ${values}`)
    }
  })

  React.useEffect(() => {
    if (!profileState?.username) return
    CheckUsernameAvailabilty(profileState.username).then((res) => {
      setUnameError({ error: false, message: '' })
    }).catch((error) => {
      setUnameError({ error: false, message: 'Username not available!' })
    })
  }, [profileState?.username])

  if (!profileState) {
    navigate('/login')
    return <></>
  }





  const onProfileChange = <T extends IProfile>(key: keyof T) => (value: T[keyof T]) => {
    setProfileState({ ...profileState, [key]: value })
  }

  return (
    <Container sx={{ paddingBlock: 5, paddingInline: 50, minHeight: '85vh', width: '70%' }}>
      <Grid container spacing={2}>
        <Grid xs={4} sx={{ boxShadow: '1px 0px 1px -1px #757575' }}>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
          >
            <ListItemButton onClick={() => setProfileType('info')} >
              <Typography variant='h6' fontSize={14} >Edit Profile</Typography>
            </ListItemButton>

            <ListItemButton onClick={() => setProfileType('social')} >
              <Typography variant='h6' fontSize={14} >Change Username</Typography>
            </ListItemButton>

            <ListItemButton onClick={() => setProfileType('logs')} >
              <Typography variant='h6' fontSize={14} >Email & Passwords</Typography>
            </ListItemButton>

            <ListItemButton onClick={() => setProfileType('social')} >
              <Typography variant='h6' fontSize={14} >Social Links</Typography>
            </ListItemButton>
          </List>
        </Grid>



        <Grid xs={8}>
          {profileType === "info" &&
            <Grid container gap={2}>
              <Grid xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <ProfilePicture profileUrl={profileState.profileUrl} name={profileState.name} />
              </Grid>
              <Grid xs={6} sx={{ marginBottom: 3 }}>
                <Typography variant='h6' fontSize={15} >{profileState.username}</Typography>
                <Typography variant='h6' fontSize={14} style={{ cursor: 'pointer', lineHeight: 1 }} color="blueviolet" >Change profile photo</Typography>
              </Grid>

              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Name</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='Enter Your name here'
                  value={profileState.name}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
              </Grid>

              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Username</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='Choose unique username'
                  value={profileState.username}
                  disabled
                />
              </Grid>

              {/******************  Bio Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Bio</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='write your bio'
                  value={profileState.profileBio}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
              </Grid>

              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >About</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='Describe yourself'
                  value={profileState.profileDesc}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
              </Grid>

              {/******************  Email Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Email</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='example@email.com'
                  value={profileState.email}
                  disabled
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
              </Grid>

              {/******************  Phone number Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Phone</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="name"
                  size='small'
                  placeholder='Enter your phone number'
                  value={profileState.phone}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
              </Grid>

              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              </Grid>
              <Grid xs={6}>
                <Button variant='contained'
                  size='small' sx={{ textTransform: 'capitalize' }}
                  disabled
                >Update</Button>
              </Grid>

            </Grid>
          }


          {profileType === "logs" &&
            <Grid container gap={2} >
              <Grid xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <ProfilePicture profileUrl={profileState.profileUrl} name={profileState.name} />
              </Grid>
              <Grid xs={6} sx={{ marginBottom: 3 }} >
                <Typography variant='h6' fontSize={15} >{profileState.username}</Typography>
                <Typography variant='h6' fontSize={14} style={{ cursor: 'pointer', lineHeight: 1 }} color="blueviolet" >Change profile photo</Typography>
              </Grid>

              {/******************  old Password Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={14} fontWeight={500} >Old Password</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="oldPassword"
                  size='small'
                  sx={{ fontSize: 14 }}
                  placeholder='Enter Your name here'
                  value={values.oldPassword}
                  onChange={handleChange}
                  error={errors.oldPassword ? true : false}
                  helperText={errors.oldPassword}
                />
              </Grid>

              {/******************  New Password Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={14} fontWeight={500} >New password</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="newPassword"
                  size='small'
                  sx={{ fontSize: 14 }}
                  placeholder='Enter new password'
                  value={values.newPassword}
                  onChange={handleChange}
                  error={errors.newPassword ? true : false}
                  helperText={errors.newPassword}
                />
              </Grid>

              {/******************  Confirm New Password Field *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={14} fontWeight={500} >Confirm Password</Typography>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id="confirmNewPassword"
                  size='small'
                  sx={{ fontSize: 14 }}
                  placeholder='Confirm new password'
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  error={errors.confirmNewPassword ? true : false}
                  helperText={errors.confirmNewPassword}
                />
              </Grid>

              {/******************  Submit button  *************************/}
              <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              </Grid>
              <Grid xs={6}>
                <Button variant='contained'
                  size='small' sx={{ textTransform: 'capitalize' }}
                  disabled
                >Change</Button>
              </Grid>

            </Grid>
          }


          {profileType === "social" &&
            <SocialLinksSection />
          }
        </Grid>

      </Grid>
    </Container>
  )
}

