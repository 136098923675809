import React from 'react'
import { styled, Typography } from '@mui/material';
import HomeBG from '../assets/homebg.webp'
import BG from '../assets/homebg.webp'

const HomePage = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundImage: `url(${BG})`,
  backgroundSize: 'cover',
  ":before": {
    content: "",
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.65)'
  }
}));

export const AppHomePage = () => {
  return (
    <div className={`h-screen flex justify-center items-center`}
    >
      <div className="text-black text-center">
        <p className='text-4xl font-bold'>We Provide Blockchain Solutions !</p>
        <p className='text-lg font-normal leading-3'> TeamCode is a BlockChain app development Group</p>
        <p className='text-lg font-normal leading-3'>Our team specializes in Blockchain development and MERN Stack development.</p>
      </div>
    </div>
  )
}
