import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ProfilePicture } from '../../components/Navbar/ProfilePicture'
import { useAppSelector } from '../../redux'
import { selectProfileState } from '../../redux/reducers/profile'

export const SocialLinksSection = () => {
    const { profile } = useAppSelector(selectProfileState)
    const [socialLinks, setSocialLinks] = useState(profile?.socialLinks)

    if (!profile || !socialLinks) return <></>

    
    return (
        <Grid container gap={2}>
            <Grid xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <ProfilePicture profileUrl={profile.profileUrl} name={profile.name} />
            </Grid>
            <Grid xs={6} sx={{ marginBottom: 3 }}>
                <Typography variant='h6' fontSize={15} >{profile.username}</Typography>
                <Typography variant='h6' fontSize={14} style={{ cursor: 'pointer', lineHeight: 1 }} color="blueviolet" >Change profile photo</Typography>
            </Grid>

            {/******************  Github Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Github</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='Github Url'
                    value={socialLinks.githubUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            {/******************  LinkedIn Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >LinkedIn</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='LinkedIn Url'
                    value={socialLinks.linkedInUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            {/******************  Twitter Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Twitter</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='Twitter Url'
                    value={socialLinks.twitterUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            {/******************  Facebook Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Facebook</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='Facebook Url'
                    value={socialLinks.facebookUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            {/******************  Instagram Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Instagram</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='Instagram Url'
                    value={socialLinks.instagramUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            {/******************  Youtube Url Field *************************/}
            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant='subtitle1' fontSize={16} fontWeight={500} >Youtube</Typography>
            </Grid>
            <Grid xs={6}>
                <TextField
                    id="name"
                    size='small'
                    placeholder='Youtube Url'
                    value={socialLinks.youtubeUrl}
                // onChange={handleChange}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                />
            </Grid>

            <Grid xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            </Grid>
            <Grid xs={6}>
                <Button variant='contained'
                    size='small' sx={{ textTransform: 'capitalize' }}
                    disabled={JSON.stringify(profile.socialLinks) === JSON.stringify(socialLinks)}
                >Update</Button>
            </Grid>
        </Grid>
    )
}
