import { Popper, Box, styled, Typography, Button } from "@mui/material";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { ProfilePicture } from "./ProfilePicture";
import { selectProfileState, selectProfileUseername } from "../../redux/reducers/profile";
import { useAppSelector } from "../../redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuthProvider } from "../../providers";
import { MuiDropdownLink, ProfileDialogBorder } from "./NavbarComponents";

const ProfileDialog = styled(Popper)(({ theme }) => ({
    top: '15px !important',
    left: '-55px !important',
    minWidth: 200,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 8px 14px #DBA61733',
    // border: '0.1px solid #70707045',
    borderRadius: 3,
    paddingBlock: 20,
    boxSizing: 'border-box'
}));





const ButtonLink = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    color: '#707070',
    fontFamily: 'ubuntu',
    paddingInline: 4,
    paddingBlock: 5,
    textAlign: 'center',
    [':hover']: {
        background: '#f0f0f0'
    }
}));

export const ProfileDropdown = () => {
    const { isLogSuccess, logoutAsync } = useAuthProvider()
    let navigate = useNavigate()
    let isAccount = useMatch('/account')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const { profile: profileData } = useAppSelector(selectProfileState)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const onViewAccountClick = () => {
        setAnchorEl(null);
    }

    const onLogoutButtonClick = () => {
        alert('onLogoutButtonClick called')
    }

    return (
        <div>
            {!profileData || !isLogSuccess ?
                <Button variant="contained"
                    sx={{ background: '#e36176', fontSize: 15, borderRadius: 2, textTransform: "none", [":hover"]: { background: '#e36176' } }}
                    onClick={() => navigate('/login')}
                >Login/Register</Button>
                :
                <>
                    <div aria-describedby={id} style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
                        <ProfilePicture profileUrl={profileData.profileUrl} name={profileData.name} />
                        <ArrowDropDownIcon style={{ marginLeft: -.5, cursor: 'pointer', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: '.1s linear' }} />
                    </div>
                    <ProfileDialog id={id} open={open} anchorEl={anchorEl}>
                        <Box display='flex' justifyContent='flex-start' alignItems='center' gap={1} paddingX={2} >
                            <ProfilePicture profileUrl={profileData.profileUrl} name={profileData.name} />
                            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start' >
                                <Typography variant='body1' fontSize={14} color="#575757" fontWeight={500} >{profileData.name}</Typography>
                                <Typography variant='body1' fontSize={12} color="#707070" fontWeight={500} sx={{ opacity: '80%' }} >{profileData.email}</Typography>
                            </Box>
                        </Box>

                        <ProfileDialogBorder />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '100%' }} >
                            <MuiDropdownLink to={`/${profileData.username}`} >Profile</MuiDropdownLink>
                            <MuiDropdownLink to="/write" >Write</MuiDropdownLink>
                            <MuiDropdownLink to="/settings" >Setting</MuiDropdownLink>
                        </Box>
                        <ButtonLink variant='body1' onClick={logoutAsync} fontSize={16} >Logout</ButtonLink>
                    </ProfileDialog>
                </>
            }
        </div>
    );
}