import { AlertColor } from '@mui/material'
import React, { ReactNode } from 'react'
import { MuiSnackbar } from '../dialogs/MuiSnackbar'

interface IWidthProvider {
    windowSize: number
}
const WidthContext = React.createContext<IWidthProvider>({
    windowSize: 0
})

export const useWidthProvider = () => React.useContext(WidthContext)

export const WidthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [windowSize, setWindowSize] = React.useState(window.innerWidth)


    React.useEffect(() => {
        function handleResize() {
            setWindowSize(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    return (
        <WidthContext.Provider value={{
            windowSize
        }}>
            {children}
        </WidthContext.Provider>
    )
}



