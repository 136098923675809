import { styled, Typography } from '@mui/material';
import React from 'react'
import { useAuthProvider } from '../../providers';
import { ProfilePicEditIcon, TcAccountCircle } from './NavbarComponents';



interface IProfilePicture {
    height?: number | string
    width?: number | string
    size?: number
    profileUrl: string,
    name: string,
    showEdit?: boolean
}

export const ProfilePicture = ({ profileUrl, name, height, width, size, showEdit }: IProfilePicture) => {
    const { isLogSuccess } = useAuthProvider()
    return (
        <TcAccountCircle sx={{ height: height || 40, width: width || 40 }}>
            {isLogSuccess && showEdit && <ProfilePicEditIcon />}
            {profileUrl.length > 0 ? <img width='100%' height='100%' src='' /> : <Typography fontSize={size || 18} fontWeight={600}>{name.charAt(0).toUpperCase()}</Typography>}
        </TcAccountCircle>
    )
}
