import React from 'react'
import { styled, Typography } from '@mui/material';
import { ColorSchem } from '../constants';
import { useWidthProvider } from '../providers';

const AboutPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '90vh',
}));

export const AppAboutPage = () => {
  const { windowSize } = useWidthProvider()
  return (
    <AboutPage>
      <Typography variant='h2' color={'#707070'} fontFamily="ubuntu" textAlign="center" >About</Typography>
      <Typography variant='body1' color={'#707070'} fontStyle="italic" textAlign="justify" paddingX={2} >TeamCode is a Web Apps development Group.</Typography>
      <Typography variant='body1' width={windowSize > 800 ? "60%" : "100%"} textAlign="justify" paddingX={2} color={'#707070'} justifyContent="space-around" >Our team specializes in Blockchain development and MERN Stack development.
        We're a fast-growing company. We value curiosity, diversity and open-mindedness. TeamCode is a fun place to work. We
        solve interesting problems, surrounded by exceptional people, while making customers happy. We work hard, but also take our
        personal lives and experiences seriously.</Typography>
    </AboutPage>
  )
}
