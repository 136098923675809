import { Box, Fade, Paper, Popper, styled, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MuiDropdownLink } from './NavbarComponents';

const MenuDialog = styled(Popper)(({ theme }) => ({
    top: '24px !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 8px 14px #DBA61733',
    // border: '0.2px solid #70707045',
    minWidth: 150,
    borderRadius: 3,
    paddingBlock: 25,
}));

export interface IMenuDropdown {
    title: string,
    links: {
        title: string,
        path: string
    }[],
}

export const MenuDropdown = ({ title, links }: IMenuDropdown) => {
    let navigate = useNavigate()
    const [menuState, setMenuState] = React.useState<{ anchorEl: null | HTMLElement, showPopper: boolean }>({ showPopper: false, anchorEl: null });
    const showMenuItems = (event: React.MouseEvent<HTMLElement>) => setMenuState({ showPopper: true, anchorEl: event.currentTarget })
    const handleNavigate = (path: string) => navigate(path)


    return (
        <div >
            <Typography

                aria-describedby={`${title}-transition-popper`}
                onMouseEnter={showMenuItems}
                variant='body1'
                fontSize={16}
                sx={{ textTransform: 'none', cursor: 'pointer', fontFamily: 'ubuntu', color: '#707070', [':hover']: { color: '#4daff9' } }}
            >{title}<ArrowDropDownIcon sx={{ marginLeft: -.5 }} /></Typography>

            <MenuDialog id={`${title}-transition-popper`}
                open={menuState.showPopper} anchorEl={menuState.anchorEl}
                onMouseLeave={() => setMenuState({ showPopper: false, anchorEl: null })}
                placement="bottom" transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            {links.map((link, index) => {
                                return <MuiDropdownLink key={index} to={link.path} >{link.title}</MuiDropdownLink>
                            })}
                        </Box>
                    </Fade>
                )}
            </MenuDialog>
        </div>
    );











    // let navigate = useNavigate()
    // const [menuState, setMenuState] = React.useState<{ anchorEl: null | HTMLElement, open: boolean }>({ open: false, anchorEl: null });

    // const showMenuItems = (event: React.MouseEvent<HTMLElement>) => setMenuState({ anchorEl: event.currentTarget, open: true })
    // const handleNavigate = (key: string) => navigate(key)

    // const canBeOpen = menuState.open && Boolean(menuState.anchorEl);
    // const menuId = canBeOpen ? `${title}-transition-popper`  : undefined;

    // return (
    //     <>
    //         <Typography
    //             aria-describedby={menuId}
    //             onMouseEnter={showMenuItems}
    //             onMouseLeave={() => setMenuState({ anchorEl: null, open: false })}
    //             variant='h6'
    //             fontSize={14}
    //             sx={{ textTransform: 'none', cursor: 'pointer' }}
    //             color={'#3B3B3B'}
    //         >Technology</Typography>

    //         <Popper
    //             id={menuId}
    //             open={menuState.open} anchorEl={menuState.anchorEl}
    //             placement="bottom" transition
    //         >
    //             <Paper>
    //                 {links.map((link, index) => {
    //                     return <Box>
    //                         <Typography key={index} variant='h6' fontSize={14} sx={{ cursor: 'pointer' }} onClick={() => handleNavigate(link.path)} >{link.title}</Typography>
    //                     </Box>
    //                 })}
    //             </Paper>
    //         </Popper>
    //     </>
    // );
}
