import React from 'react';
import { Route, Routes, useMatch } from "react-router-dom";
import { AppAboutPage, AppContactPage, AppFooterPage, AppHireUsPage, AppHomePage, LoginPage, LoginScreen, SignUpPage, SignUpScreen } from './pages';

import { WriteNewArticle } from './pages/Blog/WriteNewArticle';
import { Navbar } from './components';
import { UserProfile } from './pages/Profile/UserProfile';
import { UserSettings } from './pages/Settings';
import { useAppDispatch } from './redux';
import { fetchArticlesThunk } from './redux/reducers/article';

function App() {
  const dispatch = useAppDispatch()
  let isEditor = useMatch("/write");

  React.useEffect(() => {
    dispatch(fetchArticlesThunk())
  }, [])

  return (
    <>
      {!isEditor && <Navbar />}
      <Routes>
        <Route path="/" element={<AppHomePage />} />
        <Route path="/:username" element={<UserProfile />} />
        <Route path="/about" element={<AppAboutPage />} />
        <Route path="/contact" element={<AppContactPage />} />
        <Route path="/hire-us" element={<AppHireUsPage />} />
        <Route path="/write" element={<WriteNewArticle />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<SignUpScreen />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/settings" element={<UserSettings />} />
      </Routes>
      <AppFooterPage />
    </>
  );
}

export default App;
