import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useAppDispatch } from '../redux';
import { fetchUserProfileThunk } from '../redux/reducers/profile/ThunkAPIs';
import { AuthService } from '../services';

interface IAuthContext {
    isLogSuccess: boolean,
    setAuthState: (log: boolean) => void
    logoutAsync: () => void
}


export const AuthContext = createContext<IAuthContext>({
    isLogSuccess: false,
    setAuthState: () => { },
    logoutAsync: () => { }
});
export const useAuthProvider = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useAppDispatch()
    const [auth, setAuth] = useState<boolean>(false)

    useEffect(() => {
        const profileId = localStorage.getItem("profileId")
        if (!profileId) return setAuth(false)
        dispatch(fetchUserProfileThunk(JSON.parse(profileId)))
        setAuth(true)
    }, [])


    const logoutAsync = () => {
        localStorage.removeItem("profileId");
        setAuth(false)
    }

    return (
        <AuthContext.Provider value={{ isLogSuccess: auth, setAuthState: setAuth, logoutAsync }}>
            {children}
        </AuthContext.Provider>
    )
}
