import { Visibility, VisibilityOff } from '@mui/icons-material';
import { OutlinedInput, FormControl, IconButton, InputAdornment, InputLabel, styled, TextField, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../../../providers';
import { useAppDispatch } from '../../../redux';
import { fetchUserProfileThunk } from '../../../redux/reducers/profile/ThunkAPIs';
import { AuthService } from '../../../services';
import { Divider } from '../AuthComponents';


const LoginP = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
}));



interface ILoginState {
    email: string
    password: string
    showPassword: boolean
}
export const LoginScreen = () => {
    const dispatch = useAppDispatch()
    const nevigate = useNavigate();
    const { setAuthState } = useAuthProvider()
    const [loginState, setLoginState] = useState<ILoginState>({ email: 'kamal@gmail.com', password: '123456789', showPassword: false })
    const [logType, setLogType] = useState<"email" | "password">("email")


    const ChangeLoginState = <T extends ILoginState>(key: keyof T) => (value: T[keyof T]) => {
        setLoginState({ ...loginState, [key]: value })
    }

    const verifyEmailLogin = () => {
        if (logType === "email") {
            AuthService.verifyEmailLogin(loginState.email).then(res => {
                setLogType("password")
            }).catch((error) => {
                alert(error.message)
            })
        } else {
            AuthService.authenticate(loginState.email, loginState.password).then((res) => {
                dispatch(fetchUserProfileThunk(res))
                nevigate('/')
                setAuthState(true)
            }).catch(() => {
                alert('Invalid Credentials')
            })
        }

    }

    const gfgApiLogin = (logType: "google" | "facebook" | "github") => () => {

        if (logType === "google") return alert("Google Login Clicked")
        if (logType === "facebook") return alert("Facebook Login Clicked")
        if (logType === "github") return alert("Github Login Clicked")
    }


    return (
        <LoginP>
            {/* <Typography variant='h4' fontFamily={"ubuntu"} marginBottom={2} marginTop={5} >Sign In Here</Typography> */}
            <Typography variant='h4' fontFamily={"ubuntu"} marginBottom={2} >Welcome Back</Typography>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    sx={{ borderRadius: 1 }}
                    placeholder='example@email.com'
                    value={loginState.email}
                    onChange={(e) => ChangeLoginState('email')(e.target.value)}
                    disabled={logType === "password"}
                    endAdornment={
                        logType === "password" && <Typography
                            style={{ fontSize: 14, cursor: 'pointer' }}
                            color="blueviolet" onClick={() => setLogType('email')
                            }
                        >Edit</Typography>
                    }
                />
            </FormControl>
            {logType === "password" && <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    type={loginState.showPassword ? 'text' : 'password'}
                    value={loginState.password}
                    onChange={(e) => ChangeLoginState('password')(e.target.value)}
                    placeholder="password"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => ChangeLoginState('showPassword')(!loginState.showPassword)}
                                edge="end"
                            >
                                {loginState.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>}
            <Button
                variant='contained'
                sx={{ borderRadius: 1, marginTop: 2, marginBottom: 2, textTransform: 'none', height: 44, width: '44ch' }}
                onClick={verifyEmailLogin}
            >Continue</Button>

            {logType === "email" ?
                <Typography fontSize={14}>No account? <Link to="/register">Create one</Link></Typography>
                :
                <Typography fontSize={14}>Forgot <Link to="/register" style={{ textDecoration: 'none' }}>password</Link> ?</Typography>
            }

            <Divider>
                <span style={{ textAlign: 'center', flex: '0.2 0 auto', margin: 0, fontSize: 12, textTransform: 'capitalize' }}>Or login with</span>
            </Divider>

            <Box sx={{ width: '40ch', marginTop: 2, marginBottom: 4 }} display="flex" justifyContent="space-between" alignItems="center">
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('google')}
                >Google</Button>
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('facebook')}
                >Facebook</Button>
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('github')}
                >Github</Button>
            </Box>
        </LoginP>
    )
}
