import axios from 'axios'
import { SERVER_BASE_URL } from '../constants';


export const ServerBaseAPI = axios.create({
    baseURL: SERVER_BASE_URL,
    headers: {'content-type': 'application/json'}
});

const h = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
    },
}