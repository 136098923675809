import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { ProfilePicture } from '../../components/Navbar/ProfilePicture';
import { useNavigate, useParams } from 'react-router-dom';
import { IProfile } from '../../interfaces/IProfile';
import { FetchProfileByUsername } from '../../services/ProfileService';
import { ProfileNotFound } from './ProfileNotFound';
import { LoadingOverlay } from '../../dialogs/LoadingOverlay';
import { SleepAsync } from '../../helpers';
import { ProfileBorder, ProfilePictureWrapper, SocialLink } from './ProfileComponents';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useAuthProvider } from '../../providers';
import { ProfileBanner } from './ProfileBanner';

export const UserProfile: React.FC = () => {
  let { username } = useParams()
  let navigate = useNavigate()
  const { isLogSuccess } = useAuthProvider()
  const [profileState, setProfileState] = React.useState<IProfile | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)


  React.useEffect(() => {
    if (!username) return
    (async () => {
      setIsLoading(true)
      await SleepAsync(2000)
      FetchProfileByUsername(username).then((res) => {
        setProfileState(res.data.data)
        setIsLoading(false)
      }).catch((error) => {
        setProfileState(null)
        setIsLoading(false)
      })
    })()
  }, [username])


  if (isLoading) {
    return <LoadingOverlay open={true} onClose={() => { }} />
  }

  if (!profileState) {
    return <ProfileNotFound />
  }


  return (
    <Container sx={{ paddingBlock: 5, paddingInline: 50, minHeight: '85vh', width: '70%' }}>
      <Grid container spacing={2}>

        <Grid xs={12} md={12} >
          <ProfileBanner profileUrl={profileState.profileUrl} name={profileState.name} bannerUrl={profileState.profileBannerUrl} />
        </Grid>

        <Grid xs={12} md={9} display="flex" flexDirection="column" justifyContent="center" marginTop={6} paddingLeft={3.5} >
          <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">
            {`${profileState.name}`}
          </Typography>

          <Typography sx={{ color: '#707070', fontSize: 16, width: '70%' }}>{'Full Stack Blockchain & MERN Stack Developer | Web3 | Defi | AWS Ethereum'}</Typography>
          {/* <Typography sx={{ fontFamily: 'ubuntu', color: '#707070', fontSize: 14 }} >{`${100} followers`}</Typography> */}

        </Grid>

        <Grid xs={12} md={3} display="flex" flexDirection="column" justifyContent="flex-end">
          <Typography component="span" sx={{ color: '#707070', fontSize: 12, fontFamily: 'ubuntu' }}>{`100K followers`}</Typography>
          {isLogSuccess ? <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} onClick={() => navigate('/settings')}>Edit Profile</Button> :
            <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }}>Follow</Button>}
        </Grid>

        <ProfileBorder />
        <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">About</Typography>
        <Grid xs={12} md={12} >
          <Typography variant='h6' marginY={4} color="#707070" fontFamily="ubuntu" textAlign="center" textTransform="capitalize">
            {profileState.profileDesc}
          </Typography>
        </Grid>

        <ProfileBorder />
        <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">Articles</Typography>
        <Grid xs={12} md={12} >
          {[].length > 0 ?
            <></>
            :
            <Typography variant='h6' marginY={4} color="#707070" fontFamily="ubuntu" textAlign="center" textTransform="capitalize">No article yet</Typography>
          }
        </Grid>

        <ProfileBorder />
        <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">Social</Typography>
        <Grid xs={12} md={12} >
          <Box>
            {!profileState.socialLinks.instagramUrl && <SocialLink style={{ color: '#c32aa3' }} href="https://www.instagram.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><InstagramIcon className="social_icon" /></SocialLink>}
            {!profileState.socialLinks.linkedInUrl && <SocialLink style={{ color: '#0077b5' }} href="https://www.linkedin.com/in/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><LinkedInIcon className="social_icon" /></SocialLink>}
            {!profileState.socialLinks.twitterUrl && <SocialLink style={{ color: '#55acee' }} href="https://twitter.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><TwitterIcon className="social_icon" /></SocialLink>}
            {!profileState.socialLinks.youtubeUrl && <SocialLink style={{ color: '#ff0000' }} href="https://www.youtube.com/@TeamCodeOfficial" target="_blank" rel="noreferrer" className="mr-1"><YouTubeIcon className="social_icon" /></SocialLink>}
            {!profileState.socialLinks.facebookUrl && <SocialLink style={{ color: '#3b5998' }} href="https://github.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><FacebookIcon className="social_icon" /></SocialLink>}
            {!profileState.socialLinks.githubUrl && <SocialLink style={{ color: '#00405d' }} href="https://github.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><GitHubIcon className="social_icon" /></SocialLink>}
          </Box>
        </Grid>
      </Grid>
    </Container >
  )
}

















// export const UserProfile: React.FC = () => {
//   let { username } = useParams()
//   let navigate = useNavigate()
//   const { isLogSuccess } = useAuthProvider()
//   const [profileState, setProfileState] = React.useState<IProfile | null>(null)
//   const [isLoading, setIsLoading] = React.useState<boolean>(false)


//   React.useEffect(() => {
//     if (!username) return
//     (async () => {
//       setIsLoading(true)
//       await SleepAsync(2000)
//       FetchProfileByUsername(username).then((res) => {
//         setProfileState(res.data.data)
//         setIsLoading(false)
//       }).catch((error) => {
//         setProfileState(null)
//         setIsLoading(false)
//       })
//     })()
//   }, [username])


//   if (isLoading) {
//     return <LoadingOverlay open={true} onClose={() => { }} />
//   }

//   if (!profileState) {
//     return <ProfileNotFound />
//   }


//   return (
//     <Container sx={{ paddingBlock: 5, paddingInline: 50, minHeight: '85vh', width: '70%' }}>
//       <Grid container spacing={2}>

//         <Grid xs={12} md={2} >
//           <ProfilePicture profileUrl={profileState.profileUrl} name={profileState.name} height={120} width={120} size={42} />
//         </Grid>

//         <Grid xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" >
//           <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">{profileState.name}</Typography>
//           <Typography sx={{ fontFamily: 'ubuntu', color: '#707070', fontSize: 14 }}>{`@${profileState.username}`}</Typography>
//           <Typography sx={{ fontFamily: 'ubuntu', color: '#707070', fontSize: 14 }} >{`${100} followers`}</Typography>
//           <Box>
//             {!profileState.instagramUrl && <SocialLink style={{ color: '#c32aa3' }} href="https://www.instagram.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><InstagramIcon className="social_icon" /></SocialLink>}
//             {!profileState.linkedInUrl && <SocialLink style={{ color: '#0077b5' }} href="https://www.linkedin.com/in/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><LinkedInIcon className="social_icon" /></SocialLink>}
//             {!profileState.twitterUrl && <SocialLink style={{ color: '#55acee' }} href="https://twitter.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><TwitterIcon className="social_icon" /></SocialLink>}
//             {!profileState.youtubeUrl && <SocialLink style={{ color: '#ff0000' }} href="https://www.youtube.com/@TeamCodeOfficial" target="_blank" rel="noreferrer" className="mr-1"><YouTubeIcon className="social_icon" /></SocialLink>}
//             {!profileState.facebookUrl && <SocialLink style={{ color: '#3b5998' }} href="https://github.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><FacebookIcon className="social_icon" /></SocialLink>}
//             {!profileState.githubUrl && <SocialLink style={{ color: '#00405d' }} href="https://github.com/erkamalofficial" target="_blank" rel="noreferrer" className="mr-1"><GitHubIcon className="social_icon" /></SocialLink>}
//           </Box>
//         </Grid>

//         <Grid xs={12} md={4} display="flex" flexDirection="column" justifyContent="flex-end">
//           {isLogSuccess ? <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} onClick={() => navigate('/settings')}>Edit Profile</Button> :
//             <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }}>Follow</Button>}
//         </Grid>

//         <ProfileBorder />

//         <Typography variant='h5' fontFamily="ubuntu" textTransform="capitalize">Posts</Typography>
//         <Grid xs={12} md={12} >
//           {[].length > 0 ?
//             <></>
//             :
//             <Typography variant='h6' mt={8} color="#707070" fontFamily="ubuntu" textAlign="center" textTransform="capitalize">No posts yet</Typography>
//           }
//         </Grid>


//       </Grid>
//     </Container >
//   )
// }

