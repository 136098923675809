import { Dialog, styled, Typography } from '@mui/material';
import React from 'react'
import { CircularLoading } from '../components';

const MUIDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiBackdrop-root": {
        background: '#fefdfdfc'
    },
    "& .MuiPaper-root": {
        background: 'transparent',
        boxShadow: 'none',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));


interface ICreateProjectDialog {
    open: boolean
    onClose: () => void
}
export const LoadingOverlay: React.FC<ICreateProjectDialog> = (props) => {
    return (
        <MUIDialog open={props.open}>
            <CircularLoading
                sx={{
                    color: '#DBA617',
                    strokeLinecap: 'round'
                }}
                size={140}
                thickness={4}
            />
            <Typography variant='h4' fontSize={36} marginTop={3} color="#DBA617" fontWeight={500} >Loading</Typography>
            <Typography variant='h5' fontSize={22} marginTop={0} color="#3B3B3B" fontWeight={500} >Please wait ...</Typography>
        </MUIDialog>
    )
}
