import React from 'react'
import { ProfilePicture } from '../../components/Navbar/ProfilePicture'
import { useAuthProvider } from '../../providers'
import { BannerEditIcon, ProfilePictureWrapper, TCProfileBanner } from './ProfileComponents'

interface IProfileBanner {
    profileUrl: string,
    name: string,
    bannerUrl: string
}
export const ProfileBanner = ({ name, profileUrl, bannerUrl }: IProfileBanner) => {
    const { isLogSuccess } = useAuthProvider()
    return (
        <TCProfileBanner sx={{ backgroundImage: bannerUrl !== "" ? `url(${bannerUrl})` : '' }} >
            {isLogSuccess && <BannerEditIcon />}
            <ProfilePictureWrapper>
                <ProfilePicture profileUrl={profileUrl} name={name} height="100%" width="100%" size={42} showEdit={true} />
            </ProfilePictureWrapper>
        </TCProfileBanner>
    )
}
