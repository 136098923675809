import { Box, styled } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

export const ProfileBorder = styled('hr')(({ theme }) => ({
    width: '100%',
    borderWidth: 0,
    backgroundColor: '#70707045',
    opacity: '66%',
    height: 1.5,
    marginBlock: 15
}));

export const SocialLink = styled('a')(({ theme }) => ({
    color: '#d1d1d1',
    ['&:hover']: {
        color: 'inherit'
    }
}));


export const TCProfileBanner = styled('div')(({ theme }) => ({
    width: '100%',
    height: 200,
    borderWidth: 0,
    backgroundColor: '#70707045',
    marginBlock: 15,
    borderRadius: 8,
    position: 'relative'
}));

// //////////////////////////////////////
// Profile Banner Components
// //////////////////////////////////////
export const ProfilePictureWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -60,
    left: 25,
    height: 120,
    width: 120,
    border: '4px solid #fff',
    borderRadius: 60,
}));

export const BannerEditIcon = styled(ModeEditOutlineIcon)(({ theme }) => ({
    position: 'absolute',
    cursor: 'pointer',
    top: 12,
    right: 12,
    height: 36,
    width: 36,
    background: '#70707045',
    borderRadius: 18,
    padding: 5
}));