import { Visibility, VisibilityOff } from '@mui/icons-material';
import { OutlinedInput, FormControl, IconButton, InputAdornment, InputLabel, styled, TextField, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthService } from '../../services';


const LoginP = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#fdfdfd'
}));

interface ILoginState {
    email: string
    password: string
    showPassword: boolean
}
export const LoginPage = () => {
    const nevigate = useNavigate()
    const [loginState, setLoginState] = useState<ILoginState>({ email: 'kamal@gmail.com', password: '325b3$@54222322#42r22893', showPassword: false })

    const ChangeLoginState = <T extends ILoginState>(key: keyof T) => (value: T[keyof T]) => {
        setLoginState({ ...loginState, [key]: value })
    }


    const handleLogin = () => {
        if (loginState.password.length < 8) return alert('The password must be greater than 8 characters.')
        AuthService.authenticate(loginState.email, loginState.password).then(res => {
            nevigate('/')
        }).catch(() => {
            alert('Invalid Credentials')
        })
    }

    return (
        <LoginP>
            <Typography variant='h4' fontFamily={"ubuntu"} marginBottom={2} >Sign In Here</Typography>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    placeholder='example@email.com'
                    value={loginState.email}
                    onChange={(e) => ChangeLoginState('email')(e.target.value)}
                    disabled
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    type={loginState.showPassword ? 'text' : 'password'}
                    value={loginState.password}
                    onChange={(e) => ChangeLoginState('password')(e.target.value)}
                    placeholder="password"
                    disabled
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => ChangeLoginState('showPassword')(!loginState.showPassword)}
                                edge="end"
                            >
                                {loginState.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box sx={{ m: 0.3, width: '40ch', display: 'flex', justifyContent: 'space-around' }}>
                <Link to="/forgot-password">Forgot password</Link>
                <Link to="/signup">Register here</Link>
            </Box>
            <Button variant='contained' sx={{ borderRadius: 13, marginTop: 2, textTransform: 'none', paddingInline: 10 }} onClick={handleLogin} >Access</Button>
        </LoginP>
    )
}
