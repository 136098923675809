import axios from "axios";

const AUTH_BASE_URL = "http://localhost:9001/api/auth";

const register = (name: string, email: string, password: string) => {
    return axios.post(`${AUTH_BASE_URL}/signup`, {
        name,
        email,
        password,
    });
};

const verifyEmailLogin = (email: string) => {
    return new Promise((resolve, reject) => {
        axios.post(`${AUTH_BASE_URL}/emailverify`, { email }).then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data));
            }
            localStorage.setItem("token", JSON.stringify(response.data));
            resolve(response.data);
        }).catch(err => {
            reject(err)
        })
    })
};


const authenticate = (email: string, password: string) => {
    return new Promise<string>((resolve, reject) => {
        axios.post(`${AUTH_BASE_URL}/login`, { email, password }).then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data));
            }
            localStorage.setItem("profileId", JSON.stringify(response.data._id));
            resolve(response.data._id);
        }).catch(err => {
            reject(err)
        })
    })
};

const logout = () => {
    localStorage.removeItem("token");
};

const getCurrentUser = () => {
    const user = localStorage.getItem("token")
    if (!user) return null
    return JSON.parse(user);
};

export const AuthService = {
    register,
    verifyEmailLogin,
    authenticate,
    logout,
    getCurrentUser,
};