import { createSlice } from '@reduxjs/toolkit'
import { IProfile } from '../../../interfaces/IProfile'
import { RootState } from '../../Store'
import { IProfileReducerState } from './Interfaces'
import { fetchUserProfileThunk, updateUserProfileThunk } from './ThunkAPIs'


const initialState: IProfileReducerState = {
    profile: null,
    status: "pending"
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfileThunk.pending, (state) => {
            state.status = "pending"
            return state
        })
        builder.addCase(fetchUserProfileThunk.fulfilled, (state, action: any) => {
            return { ...state, status: 'fulfilled',  data: action.payload }
        })
        builder.addCase(fetchUserProfileThunk.rejected, (state, action) => {
            state.status = "rejected"
            return state
        })


        builder.addCase(updateUserProfileThunk.fulfilled, (state, action: any) => {
            return { ...state, status: 'fulfilled',  data: action.payload }
        })
    },
})

// export const { increment, decrement, incrementByAmount } = detailSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectProfileState = (state: RootState) => state.profile
export const selectProfileUseername = (state: RootState) => state.profile.profile?.username

export default profileSlice.reducer