import { Button, styled, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { TextEditor } from '../../components';
import { MuiMenuLink } from '../../components/Navbar/NavbarComponents';

const BlogContainer = styled('div')(({ theme }) => ({
    minHeight: '100vh',
    
    boxSizing: 'border-box'

}));

const ArticleNavbar = styled('div')(({ theme }) => ({
    height: 70,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBlock: 10,
    paddingInline: 20,
    borderBottom: '2px solid #d8d8d8'
}));



const ArticleContainer = styled('div')(({ theme }) => ({
    background: '#efefef',
    marginTop: 20,
    width: '82%',
    margin: '0 auto'
}));

const NavButton = styled(Button)(({ theme }) => ({
    borderRadius: 20,
    textTransform: 'capitalize',
    fontWeight: 500,
    paddingInline: 15
}))


const HeaderContent = styled('div')(({ theme }) => ({
    marginTop: 10,
    marginBottom: 20,
    paddingInline: 10
}));

const BlogTitle = styled('input')(({ theme }) => ({
    fontSize: 28,
    fontWeight: 600,
    fontFamily: 'Ubuntu',
    border: 'none',
    outline: 'none',
    background: 'transparent',
}));

const SiteLogo = styled(Link)(({ theme }) => ({
    color: '#eeb317',
    fontSize: 24,
    fontFamily: 'Ubuntu',
    textDecoration: 'none',
    [":hover"]: {
        color: '#eeb317'
    }
}));


export const EditorPage = () => {
    const [previewMode, setPreviewMode] = useState(false)
    const [initalState, setInitalState] = useState<string | null>(null)

    const PublishPost = () => {
        console.log("Post", { v: initalState })
    }

    return (
        <BlogContainer>
            <ArticleNavbar>
                <SiteLogo to="/">TeamCode</SiteLogo>
                <div >
                    <MuiMenuLink to="/" >Back to Home</MuiMenuLink>
                    <NavButton variant='outlined' size='small' sx={{ marginLeft: 2 }} onClick={() => setPreviewMode(!previewMode)}>{!previewMode ? 'Preview' : 'Back To Editor'}</NavButton>
                    <NavButton variant='contained' size='small' sx={{ marginLeft: 2 }} onClick={PublishPost} >Publish</NavButton>
                </div>
            </ArticleNavbar>
            <ArticleContainer>
                <HeaderContent>
                    <BlogTitle placeholder='Article Title...' />
                </HeaderContent>
                <TextEditor initalState={initalState} previewMode={previewMode} onChange={(val) => setInitalState(val)} />
            </ArticleContainer>
        </BlogContainer>
    )
}
