import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../Store'
import { createNewArticleThunk, fetchArticlesThunk } from './ArticleThunk'
import { IArticleReducerState } from './Interfaces'


const initialState: IArticleReducerState = {
    status: "pending",
    articles: []
}

export const articleSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchArticlesThunk.pending, (state) => {
            state.status = "pending"
            return state
        })
        builder.addCase(fetchArticlesThunk.fulfilled, (state, action: any) => {
            state.status = "fulfilled"
            return { ...state, blogs: action.payload }
        })
        builder.addCase(fetchArticlesThunk.rejected, (state, action) => {
            state.status = "rejected"
            return state
        })


        builder.addCase(createNewArticleThunk.pending, (state) => {
            state.status = "pending"
            return state
        })
        builder.addCase(createNewArticleThunk.fulfilled, (state, action) => {
            return { ...state, status: 'fulfilled', blogs: [action.payload, ...state.articles] }
        })
        builder.addCase(createNewArticleThunk.rejected, (state, action) => {
            state.status = "rejected"
            return state
        })
    },
})

// export const { increment, decrement, incrementByAmount } = detailSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const latestBlogs = (state: RootState) => state.articles.articles

export default articleSlice.reducer
export * from './ArticleThunk'