import { styled, Typography } from '@mui/material';
import React from 'react'
import { ColorSchem } from '../constants';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const AppFooter = styled('footer')(({ theme }) => ({
    background: "#292b32",
}));

const SocialLink = styled('a')(({ theme }) => ({
    color: '#d1d1d1',
    ['&:hover']: {
        color: '#05fe18'
    }
}));

const NavLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: "#fff"
}));


export const AppFooterPage = () => {
    return (
        <AppFooter className="pt-5">
            <Typography variant='body1' color="#fff" textAlign="center">Follow Us On</Typography>
            <div className="d-flex justify-content-center my-2">
                <SocialLink href="https://twitter.com/erkamalofficial" target="_blank" rel="noreferrer" className="mx-3"><TwitterIcon className="social_icon" /></SocialLink>
                <SocialLink href="https://www.youtube.com/@TeamCodeOfficial" target="_blank" rel="noreferrer" className="mx-3"><YouTubeIcon className="social_icon" /></SocialLink>
                <SocialLink href="https://www.instagram.com/erkamalofficial" target="_blank" rel="noreferrer" className="mx-3"><InstagramIcon className="social_icon" /></SocialLink>
                <SocialLink href="https://www.linkedin.com/in/erkamalofficial" target="_blank" rel="noreferrer" className="mx-3"><LinkedInIcon className="social_icon" /></SocialLink>
                <SocialLink href="https://github.com/erkamalofficial" target="_blank" rel="noreferrer" className="mx-3"><GitHubIcon className="social_icon" /></SocialLink>
            </div>
            <div className="d-flex justify-content-center mb-2 mt-4">
                <NavLink className="px-2" to="/">Home</NavLink >
                <NavLink className="px-2" to="/about-us">About</NavLink >
                <NavLink className="px-2" to="/blog">Blogs</NavLink >
                <NavLink className="px-2" to="/contact-us">Contact Us</NavLink >
            </div>
            <p className='text-xs text-white text-center mb-0 pb-1'>&copy; 2022 TeamCode | All Rights Reserverd</p>
        </AppFooter>
    )
}
