import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { OutlinedInput, FormControl, IconButton, InputAdornment, InputLabel, styled, TextField, Typography, Button, Box, Checkbox, OutlinedTextFieldProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Divider } from '../AuthComponents';


const LoginP = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
}));

interface ISignUpState {
    name: string
    email: string
    password: string
    showPassword: boolean
}
export const SignUpScreen = () => {
    const [signUpState, setSignUpState] = useState<ISignUpState>({ name: '', email: '', password: '', showPassword: false })

    const ChangeSignUpState = <T extends ISignUpState>(key: keyof T) => (value: T[keyof T]) => {
        setSignUpState({ ...signUpState, [key]: value })
    }


    const { values: stateValues, errors, handleChange, handleSubmit } = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
        },
        validate: (values) => {
            let error: any = {}
            if (values.name === '') {
                error.name = "Name shouldn't be empty."
            } else if (values.name.length < 3 || values.name.length > 15) {
                error.name = "Name shouldn't be between 3 to 15."
            } else if (values.email === '') {
                error.email = "Email Field Shouldn't be empty."
            } else if (!values.email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) {
                error.email = "Email is not valid."
            } else if (values.password === '') {
                error.password = "Password Shouldn't be empty."
            } else if (values.password.length < 8) {
                error.password = "Password minimum lenght should be 8."
            }
            console.log('Err called ', error)
            return error
        },
        onSubmit: (values) => {
            console.log('values called ', values)
            alert(`values====>" ${values}`)
        }
    })


    const gfgApiLogin = (logType: "google" | "facebook" | "github") => () => {

        if (logType === "google") return alert("Google Login Clicked")
        if (logType === "facebook") return alert("Facebook Login Clicked")
        if (logType === "github") return alert("Github Login Clicked")
    }

    return (
        <LoginP>
            <Typography variant='h4' fontFamily={"ubuntu"} marginBottom={2}>Create your account</Typography>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                    <TextField
                        id="name"
                        placeholder='Your full name'
                        value={stateValues.name}
                        onChange={handleChange}
                        error={errors.name ? true : false}
                        helperText={errors.name}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                    <TextField
                        id="email"
                        placeholder='example@email.com'
                        value={stateValues.email}
                        onChange={handleChange}
                        error={errors.email ? true : false}
                        helperText={errors.email}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                    <TextField
                        id="password"
                        variant='outlined'
                        type={signUpState.showPassword ? 'text' : 'password'}
                        value={stateValues.password}
                        onChange={handleChange}
                        placeholder="Enter password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => ChangeSignUpState('showPassword')(!signUpState.showPassword)}
                                        edge="end"
                                    >
                                        {signUpState.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={errors.password ? true : false}
                        helperText={errors.password}
                    />
                </FormControl>

            </form>
            <Typography fontSize={14}>Already have an account? <Link to="/login">Log in</Link></Typography>
            <Button type='submit' variant='contained'
                sx={{
                    borderRadius: 1, marginTop: 2, textTransform: 'none',
                    paddingInline: 10, paddingBlock: 1.5, width: '44ch'
                }}
            >Continue</Button>


            <Divider>
                <span style={{ textAlign: 'center', flex: '0.2 0 auto', margin: 0, fontSize: 12, textTransform: 'capitalize' }}>Or login with</span>
            </Divider>

            <Box sx={{ width: '40ch', marginTop: 2, marginBottom: 4 }} display="flex" justifyContent="space-between" alignItems="center">
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('google')}
                >Google</Button>
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('facebook')}
                >Facebook</Button>
                <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ borderRadius: 1, textTransform: 'none', }}
                    onClick={gfgApiLogin('github')}
                >Github</Button>
            </Box>
        </LoginP>
    )
}
