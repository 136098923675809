import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { OutlinedInput, FormControl, IconButton, InputAdornment, InputLabel, styled, TextField, Typography, Button, Box, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';


const LoginP = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#fdfdfd'
}));

interface ISignUpState {
    name: string
    email: string
    password: string
    showPassword: boolean
}
export const SignUpPage = () => {
    const [signUpState, setSignUpState] = useState<ISignUpState>({ name: '', email: '', password: '', showPassword: false })

    const ChangeSignUpState = <T extends ISignUpState>(key: keyof T) => (value: T[keyof T]) => {
        setSignUpState({ ...signUpState, [key]: value })
    }


    const handleSignUp = () => {
        console.log('Login Satte => ', signUpState)
    }

    return (
        <LoginP>
            <Typography variant='h4' fontFamily={"ubuntu"} marginBottom={2} >Sign Up Here</Typography>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    placeholder='Your full name'
                    value={signUpState.email}
                    onChange={(e) => ChangeSignUpState('name')(e.target.value)}
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    placeholder='example@email.com'
                    value={signUpState.email}
                    onChange={(e) => ChangeSignUpState('email')(e.target.value)}
                />
            </FormControl>

            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                <OutlinedInput
                    type={signUpState.showPassword ? 'text' : 'password'}
                    value={signUpState.password}
                    onChange={(e) => ChangeSignUpState('password')(e.target.value)}
                    placeholder="password"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => ChangeSignUpState('showPassword')(!signUpState.showPassword)}
                                edge="end"
                            >
                                {signUpState.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box sx={{ width: '40ch', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Typography variant='subtitle2' > <Checkbox defaultChecked />Terms of Service</Typography>
                <Link to="/login">Sign In</Link>
            </Box>
            <Button variant='contained' sx={{ borderRadius: 13, marginTop: 2, textTransform: 'none', paddingInline: 10 }} onClick={handleSignUp} >Sign Up</Button>
        </LoginP>
    )
}
