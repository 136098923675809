import { ServerBaseAPI } from "../api"





export const SavePostToDB = () => {
    return new Promise((resolve, reject) => {
        // SERVER_BASE_API.post
    })
}

export const UploadFileToServer = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
        ServerBaseAPI.post('/file', formData).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}