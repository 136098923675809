import { Box, Fade, Popper, styled } from '@mui/material';
import React from 'react'
import { MuiCancelIcon, MuiDropdownLink, MuiMenuIcon, MuiMobilkeMenuLink } from './NavbarComponents'

const MobileMenuDialog = styled(Popper)(({ theme }) => ({
    top: '22px !important',
    width: '100%',
    padding: 20,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 8px 14px #DBA61733',
    minWidth: 150,
    borderRadius: 3,
    paddingBlock: 25,
}));



export const MobileNavbar: React.FC = () => {
    const [menuState, setMenuState] = React.useState<{ anchorEl: null | HTMLElement, showPopper: boolean }>({ showPopper: false, anchorEl: null });
    const showMenuItems = (event: React.MouseEvent<HTMLElement>) =>
        setMenuState({ showPopper: !menuState.showPopper, anchorEl: menuState.anchorEl ? null : event.currentTarget })
    // const handleNavigate = (path: string) => navigate(path)

    return (
        <div>
            <div area-aria-describedby='mobile-navbar-view' onClick={showMenuItems}>
                {menuState.showPopper ? <MuiCancelIcon /> : <MuiMenuIcon />}
            </div>

            <MobileMenuDialog id='mobile-navbar-view'
                open={menuState.showPopper} anchorEl={menuState.anchorEl}
                placement="bottom-start" transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <MuiMobilkeMenuLink onClick={showMenuItems} to="/about">About</MuiMobilkeMenuLink>
                            <MuiMobilkeMenuLink to="/technology">Technology</MuiMobilkeMenuLink>
                            <MuiMobilkeMenuLink to="/techstack">TechStack</MuiMobilkeMenuLink>
                            <MuiMobilkeMenuLink onClick={showMenuItems} to="/tutorial">Tutorial</MuiMobilkeMenuLink>
                            <MuiMobilkeMenuLink onClick={showMenuItems} to="/blog">Blog</MuiMobilkeMenuLink>
                        </Box>
                    </Fade>
                )}
            </MobileMenuDialog>
        </div>
    )
}
