import React from 'react'
import { styled, Typography } from '@mui/material';
import { ColorSchem } from '../constants';

const ContactPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '90vh',
  background: ColorSchem.bgLight
}));

export const AppContactPage = () => {
  return (
    <ContactPage>
      <Typography variant='h2' color={'#fff'} textAlign="center" >Contact Page is here</Typography>
    </ContactPage>
  )
}
