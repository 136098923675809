import { Box, Container, styled, Typography } from '@mui/material';
import React from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { ProfileDropdown } from './ProfileDropdown';
import NotificationIcon from '@mui/icons-material/NotificationsNone';
import QuestionIcon from '@mui/icons-material/Help';
import { useAuthProvider, useWidthProvider } from '../../providers';
import { IMenuDropdown, MenuDropdown } from './MenuDropdown';
import { MuiMenuIcon, MuiMenuLink } from './NavbarComponents';
import { MobileNavbar } from './MobileNavbar';

const NavbarContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    background: '#fff',
    boxShadow: '3px 2px 5px #0000001f'
}));

const MuiContainer = styled(Container)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: 70,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const LogoSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5
}));

const RightSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16
}));

const SiteLogo = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#3B3B3B',
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'ubuntu'
}));


const Border = styled('hr')(({ theme }) => ({
    height: 30,
    backgroundColor: '#B8B8B8',
    borderWidth: 0,
    width: 0.4
}));

const TechnologyLinks: IMenuDropdown = {
    title: 'Technology',
    links: [
        { title: 'Node', path: '/node' },
        { title: 'Typescript', path: '/ts' },
    ]
}
const TechStackLinks: IMenuDropdown = {
    title: 'TechStack',
    links: [
        { title: 'Node', path: '/node' },
        { title: 'Typescript', path: '/ts' },
    ]
}
export const Navbar: React.FC = () => {
    let navigate = useNavigate()
    const { isLogSuccess } = useAuthProvider()
    const { windowSize } = useWidthProvider()
    console.log('windowSize==>', windowSize)
    let isAccount = useMatch('/account')
    const newReleasesIconClick = () => { alert('newReleasesIconClick called') }
    const notificationIconClick = () => { alert('notificationIconClick called') }
    const questionIconClick = () => { alert('questionIconClick called') }

    return (
        <NavbarContainer>
            <MuiContainer>
                <LogoSection>
                    <SiteLogo to='/' style={{ color: '#eeb317', fontSize: 24 }}>Team<Typography component='span' variant='h6' fontSize={24} color={'#eeb317'} fontFamily={"ubuntu"}>Code</Typography></SiteLogo>

                </LogoSection>


                {windowSize > 800 ?
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontFamily: 'abuntu' }} gap={2}>
                            <MuiMenuLink to="/about" >About</MuiMenuLink>
                            <MenuDropdown {...TechnologyLinks} />
                            <MenuDropdown {...TechStackLinks} />
                            <MuiMenuLink to="/menu" >Tutorial</MuiMenuLink>
                            <MuiMenuLink to="/blog" >Blog</MuiMenuLink>
                        </Box>

                        <RightSection>
                            {isLogSuccess &&
                                <>
                                    <Typography onClick={() => navigate('/write')} variant='body1' fontSize={16} sx={{ color: '#707070', cursor: 'pointer', fontFamily: 'ubuntu', [':hover']: { color: '#4daff9' } }} >Write</Typography>
                                    {/* <NotificationIcon onClick={newReleasesIconClick} style={{ cursor: 'pointer' }} /> */}
                                    <NotificationIcon onClick={notificationIconClick} style={{ cursor: 'pointer' }} />
                                    <QuestionIcon onClick={questionIconClick} style={{ cursor: 'pointer' }} />
                                    <Border />
                                </>
                            }
                            <ProfileDropdown />
                        </RightSection>
                    </>
                    :
                    <MobileNavbar/>
                }
            </MuiContainer>
        </NavbarContainer>
    )
}


