import { configureStore } from '@reduxjs/toolkit'
import profileReducer from './reducers/profile'
import articleReducer from './reducers/article'



export const Store = configureStore({
  reducer: {
    profile: profileReducer,
    articles: articleReducer
  },
})

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch