import { styled, Typography } from '@mui/material';
import React from 'react'
import PersonOffIcon from '@mui/icons-material/PersonOff';

const ProfileNotFoundPage = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
}));

export const ProfileNotFound: React.FC = () => {
    return (
        <ProfileNotFoundPage>
            <PersonOffIcon sx={{ color: '#757575', height: 80, width: 80 }} />
            <Typography variant='h4' color={'#707070'} fontFamily="ubuntu" textAlign="center" >Profile Not Found</Typography>
        </ProfileNotFoundPage>
    )
}
