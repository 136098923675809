import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';

export const MuiMenuLink = styled(Link)(({ theme }) => ({
    color: '#707070',
    cursor: 'pointer',
    fontFamily: 'ubuntu',
    textAlign: 'center',
    textDecoration: 'none',
    [':hover']: {
        color: '#4daff9',
    }
}));


export const MuiDropdownLink = styled(Link)(({ theme }) => ({
    width: '100%',
    color: '#707070',
    cursor: 'pointer', 
    textDecoration: 'none',
    fontFamily: 'ubuntu',
    textAlign: 'center',
    paddingInline: 4, 
    paddingBlock: 5, 
    fontSize: 16,
    fontWeight: 100,
    [':hover']: { 
        color: '#707070',
        background: '#f0f0f0' 
    }
}));

export const ProfileDialogBorder = styled('hr')(({ theme }) => ({
    width: '100%',
    borderWidth: 0,
    backgroundColor: '#70707045',
    opacity: '66%',
    height: 1.5,
    marginBlock: 15
}));



// //////////////////////////////////////
// Profile Banner Components
// //////////////////////////////////////
export const TcAccountCircle = styled('div')(({ theme }) => ({
    position: 'relative',
    boxSizing: 'border-box',
    background: '#eeb317',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const ProfilePicEditIcon = styled(CameraAltIcon)(({ theme }) => ({
    position: 'absolute',
    cursor: 'pointer',
    bottom: 12,
    right: -12,
    height: 36,
    width: 36,
    background: '#70707045',
    borderRadius: 18,
    padding: 5
}));


// //////////////////////////////////////
// Mobile Menu Components
// //////////////////////////////////////

export const MuiMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: 'pointer',
    height: 32,
    width: 32
}));

export const MuiCancelIcon = styled(ClearIcon)(({ theme }) => ({
    cursor: 'pointer',
    height: 32,
    width: 32
}));

export const MuiMobilkeMenuLink = styled(Link)(({ theme }) => ({
    color: '#707070',
    cursor: 'pointer',
    fontFamily: 'ubuntu',
    textAlign: 'start',
    textDecoration: 'none',
    [':hover']: {
        color: '#4daff9',
    }
}));