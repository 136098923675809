import { Box, CircularProgress, circularProgressClasses, CircularProgressProps, styled, Typography } from '@mui/material'
import React from 'react'

export const CircularProgressBar = styled(CircularProgress)(({ theme }) => ({
    animationDuration: '700ms',
    '.MuiCircularProgress-circle': {
        strokeDasharray: '35px 200px',
        strokeLinecap: 'round'
    },
}));

export const CircularLoading = (props: CircularProgressProps) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{ color: (theme) => theme.palette.grey[200], position: 'absolute' }}
                size={140}
                thickness={4}
                value={100}
            />
            <CircularProgressBar
                variant="indeterminate"
                disableShrink
                sx={{ position: 'absolute' }}
                size={140}
                thickness={4}
                {...props}
            />
        </Box>
    )
}











interface IScoreProgressBar extends CircularProgressProps {
    title: string
    value: number
}
export const ScoreProgressBar = (props: IScoreProgressBar) => {
    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <CircularProgress
                variant="determinate"
                sx={{ color: (theme) => theme.palette.grey[200], position: 'absolute', top: 11, left: 11 }}
                size={props.size || 164}
                thickness={5}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{ color: '#fff', boxShadow: 'inset -2px 2px 5px 10px #d4d4d4', borderRadius: '50%', position: 'absolute', top: 28, left: 28 }}
                size={props.size || 130}
                thickness={4}
                value={100}
            />
            <CircularProgressBar
                variant='determinate'
                disableShrink
                sx={{ position: 'absolute', color: props.value as number > 70 ? '#31A408' : props.value as number > 31 ? '#D8882C' : '#C60000' }}
                size={186}
                thickness={5}
                {...props}
            />
            <Box sx={{ position: 'absolute', height: 186, width: 186, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6' sx={{ color: '#575757', fontSize: 20 }}>
                    <Typography
                        component='span'
                        sx={{ fontSize: 28, fontWeight: 500, color: props.value as number > 70 ? '#149F19' : props.value as number > 31 ? '#DBA617' : '#C60000' }}
                    >{props.value}</Typography>/100</Typography>
                <Typography variant='body1' sx={{ color: '#575757', fontWeight: 600, marginTop: -1 }}>{props.title}</Typography>
            </Box>
        </Box>
    )
}














const SemiCircularProgressBar = styled(CircularProgress)(({ theme }) => ({
    width: '100% !important',
    height: '100%  !important',
    animationDuration: '650ms',
    transform: 'rotate(180deg) !important',
    '.MuiCircularProgress-circle': {
        strokeDasharray: '35px 200px',
        strokeLinecap: 'round',
    },
}));


interface IScoreProgressBar extends CircularProgressProps {
    title: string
    value: number
}
export const SemiCircleScoreBar = (props: IScoreProgressBar) => {
    const { value, ...data } = props
    return (
        <Box sx={{ position: 'relative', maxHeight: 218, maxWidth: 218, height: 'auto', width: '100%', boxSizing: 'border-box' }}>
            <SemiCircularProgressBar
                variant="determinate"
                sx={{ color: (theme) => theme.palette.grey[200], position: 'absolute' }}
                thickness={4}
                value={50}
            />
            <SemiCircularProgressBar
                variant='determinate'
                disableShrink
                sx={{ color: props.value as number > 70 ? '#149F19' : props.value as number > 31 ? '#DBA617' : '#C60000' }}
                thickness={4}
                value={props.value / 2}
                {...data}
            />
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                <Typography variant='h6' sx={{ color: '#575757', fontSize: 20 }}>
                    <Typography
                        component='span'
                        sx={{ fontSize: 28, fontWeight: 500, color: props.value as number > 70 ? '#149F19' : props.value as number > 31 ? '#DBA617' : '#C60000' }}
                    >{props.value}</Typography>/100</Typography>
                <Typography variant='body1' sx={{ color: '#575757', fontWeight: 500, marginTop: -1 }}>{props.title}</Typography>
            </Box>
        </Box>
    )
}